/** @jsx jsx */
import { jsx, Link as TLink } from "theme-ui"
import React, { Fragment } from "react"
import { Box } from 'theme-ui'
import { Link } from "gatsby"
import { readableColor } from "polished"
import useJodieConfig from "@lekoarts/gatsby-theme-jodie/src/hooks/use-jodie-config"
import Logo from "../icons/logo";
import useSiteMetadata from "@lekoarts/gatsby-theme-jodie/src/hooks/use-site-metadata";

const Navigation = ({ bg }: { bg: string }) => {
    const { siteTitle } = useSiteMetadata()

    const { navigation } = useJodieConfig()

    return (
        <Fragment>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'inline-block',
                    height: `80px`,
                    width: `80px`,
                    justifyContent: 'flex-end',
                    overFlow: 'hidden',
                    mt: 4,
                    mb: 5,
                    ml: `35px`,
                }}
            >
                <Link to="/" aria-label={`${siteTitle}, Back to Home`}>
                    <Logo />
                </Link>
            </Box>
            <nav
                aria-label="Primary Navigation"
                sx={{
                    a: {
                        color: readableColor(bg),
                        textDecoration: `none`,
                        fontSize: [1, 1, 2, 3],
                        "&:hover,&:focus": {
                            color: readableColor(bg, `primary`, `primaryLight`, false),
                        },
                    },
                    ul: {
                        mt: 2,
                        padding: 0,
                        li: {
                            listStyle: `none`,
                        },
                    },
                    height: '100%',
                    ml: `35px`,
                    variant: `navigation`,
                }}
            >
                <ul>
                    {navigation.map((navItem) => (
                        <li key={navItem.slug}>
                            <TLink as={Link} to={navItem.slug}>
                                {navItem.name}
                            </TLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </Fragment>
    )
}

export default Navigation

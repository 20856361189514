/** @jsx jsx */
import { jsx, Link, useThemeUI } from "theme-ui"
import { readableColor } from "polished"
import useSiteMetadata from "@lekoarts/gatsby-theme-jodie/src/hooks/use-site-metadata"

const Footer = ({ bg }: { bg: string }) => {
    const { siteTitle } = useSiteMetadata()

    return (
        <footer
            sx={{
                position: [`relative`, `relative`, `relative`, `fixed`],
                width: (t: any): any => [`100%`, `100%`, `100%`, `100%`],
                bottom: 0,
                textAlign: `center`,
                color: readableColor(
                    bg,
                    `textMuted`,
                    `textMutedLight`,
                    false
                ),
                fontSize: `12px`,
                p: 2,
                background: bg,
                a: {
                    color: readableColor(bg),
                    "&:hover,&:focus": {
                        color: readableColor(bg, `primary`, `primaryLight`, false),
                    },
                },
                variant: `footer`,
            }}
        >
            <div>
                &copy; {new Date().getFullYear()} by {siteTitle}.
            </div>
            <div>
                Site developed
                {` `}
                by
                {` `}
                <Link aria-label="Link to the web developer's website" href="https://www.isaacsung.net">
                    Isaac Sung
                </Link>
                .
            </div>
        </footer>
    )
}

export default Footer

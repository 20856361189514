/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import "fontsource-work-sans/latin.css"
import { Global } from "@emotion/core"
import Wrapper from "./layout-wrapper";
import Sidebar from "./sidebar"
import Footer from "./footer"
import SEO from "@lekoarts/gatsby-theme-jodie/src/components/seo"
import { SkipNavTarget, SkipNavTrigger } from "@lekoarts/gatsby-theme-jodie/src/components/skip-nav"
import { ContextProvider } from './context'

type LayoutProps = { children: React.ReactNode; color?: string }

const Layout = ({ children, color = `white` }: LayoutProps) => (
    <React.Fragment>
        <Global
            styles={(theme) => ({
                "*,*:after,*:before": {
                    boxSizing: `border-box`,
                },
                html: {
                    fontSize: `18px`,
                    WebkitTextSizeAdjust: `100%`,
                },
                img: {
                    borderStyle: `none`,
                },
                pre: {
                    fontFamily: `monospace`,
                    fontSize: `1em`,
                },
                "[hidden]": {
                    display: `none`,
                },
                "::selection": {
                    background: theme.colors.primary,
                    color: theme.colors.white,
                },
                "ul > li > code, ol > li > code, p > code": {
                    color: `#393A34`,
                    background: `#f6f8fa`,
                    padding: 2,
                },
                "@media(max-width: 600px)": {
                    html: {
                        fontSize: `16px`,
                    },
                },
            })}
        />
        <SEO />
        <SkipNavTrigger />
        <Wrapper>
            <ContextProvider>
                <Sidebar bg={color} />
            </ContextProvider>
            <main sx={{ gridColumnStart: [1, 1, 1, 2], mb: 5, mt: `32px` }}>
                <SkipNavTarget />
                {children}
            </main>
            <Footer bg={color} />
        </Wrapper>
    </React.Fragment>
)

export default Layout

/** @jsx jsx */
import { jsx, Container, Box, Close, Image, MenuButton  } from "theme-ui"
import { transparentize } from '@theme-ui/color'
import React, { Fragment, useContext } from 'react'
import { Context } from './context'
import { Link } from "gatsby"
import { readableColor } from "polished"
import Logo from "../icons/logo"
import useSiteMetadata from "@lekoarts/gatsby-theme-jodie/src/hooks/use-site-metadata"
import Navigation from "./navigation"

type SidebarProps = { bg: string }

const Sidebar = ({ bg }: SidebarProps) => {
    const { siteTitle } = useSiteMetadata()

    const {
        state: { isNavOpen },
        dispatch,
    } = useContext(Context)

    return (
        <Fragment>
            <Box
                as="header"
                sx={{
                    p: [2, 2, 2, 4],
                    width: (t: any): any => [`100%`, `100%`, `100%`, `calc(100% - ${t.sidebar.normal}px)`],
                    ml: (t: any): any => [0, 0, 0, t.sidebar.normal],
                    backgroundColor: bg,
                    position: `fixed`,
                    display: `flex`,
                    overflow: `hidden`,
                    alignItems: `center`,
                    justifyContent: `space-between`,
                    svg: {
                        fill: readableColor(bg),
                    },
                    height: `64px`,
                    zIndex: 997,
                    variant: `sidebar`,
                }}
                data-testid="sidebar"
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: ['flex', 'flex', 'flex', 'none'],
                        flexBasis: '100%',
                        pl: [3, 3, 4, 0],
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: ['inline-block', 'inline-block', 'inline-block', 'none'],
                            width: ['48px', '48px', '48px', '100%'],
                            height: ['48px', '48px', '48px', '100%'],
                            verticalAlign: 'middle'
                        }}
                    >
                        <Link to="/" aria-label={`${siteTitle}, Back to Home`}>
                            <Logo />
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: ['flex', 'flex', 'flex', 'none'],
                        flexBasis: '100%',
                        justifyContent: 'center',
                        fontSize: 0,
                    }}
                >
                    Charis Notes
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: ['flex', 'flex', 'flex', 'none'],
                        flexBasis: '100%',
                        justifyContent: 'flex-end',
                    }}
                >
                    <MenuButton
                        aria-label="Toggle Menu"
                        onClick={() => dispatch({ type: 'openNav' })}
                    />
                </Box>
            </Box>
            <Container
                sx={{
                    margin: '0 auto',
                    maxWidth: 1200,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'background',
                        height: '100%',
                        left: (t: any): any => [
                            isNavOpen ? '0' : `-${t.sidebar.normal}`,
                            isNavOpen ? '0' : `-${t.sidebar.normal}`,
                            isNavOpen ? '0' : `-${t.sidebar.wide}`,
                            '0',
                        ],
                        position: 'fixed',
                        transition: '.3s ease-in-out left',
                        width: (t: any): any => [t.sidebar.normal, t.sidebar.normal, t.sidebar.wide, t.sidebar.wide],
                        zIndex: 999,
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            left: (t: any): any => [
                                `${isNavOpen ? 0 : `-${t.sidebar.normal}`}`,
                                `${isNavOpen ? 0 : `-${t.sidebar.normal}`}`,
                                `${isNavOpen ? 0 : `-${t.sidebar.wide}`}`,
                                0,
                            ],
                            transition: '.3s ease-in-out left',
                            position: 'relative',
                        }}
                    >
                        <Navigation bg={bg} />
                    </Box>
                </Box>
                <Box
                    role="button"
                    tabIndex="0"
                    sx={{
                        backgroundColor: transparentize('black', 0.2),
                        display: [
                            isNavOpen ? 'flex' : 'none',
                            isNavOpen ? 'flex' : 'none',
                            isNavOpen ? 'flex' : 'none',
                            'none',
                        ],
                        height: '100%',
                        justifyContent: 'flex-end',
                        px: [3, 4],
                        py: 2,
                        position: 'fixed',
                        transition: '.2s linear background-color',
                        width: '100%',
                        zIndex: 998,
                        ':focus': {
                            outline: 'none',
                            backgroundColor: transparentize('black', 0.4),
                        },
                    }}
                    onClick={() => dispatch({ type: 'closeNav' })}
                    onKeyDown={event =>
                        event.key === 'Enter' ? dispatch({ type: 'closeNav' }) : {}
                    }
                >
                    <Close sx={{ width: '32px', height: '32px' }} />
                </Box>
            </Container>
        </Fragment>
    )
}

export default Sidebar

import * as React from "react"

const Logo = () => (
        <svg
            viewBox="0 0 256.00001 256.00001"
            preserveAspectRatio="xMinYMin meet"
            sx={{
                display: `inline-block`,
                position: `absolute`,
                top: 0,
                left: 0,
            }}
        >
            <title>Charis Notes</title>
            <path
                d="m 106.45263,255.27622 q -17.14522,0 -30.583364,-5.40615 -13.438144,-5.25169 -22.70583,-15.29168 -9.113224,-10.19445 -14.05599,-24.86829 -4.788304,-14.8283 -4.788304,-33.67259 0,-10.34892 1.390153,-21.93352 1.544614,-11.58461 4.633843,-23.47814 4.788304,-18.84429 12.511375,-35.680589 7.723072,-16.990757 17.763065,-31.35567 10.194454,-14.364913 22.242445,-25.94952 12.202457,-11.739069 25.640597,-19.925525 13.43814,-8.1864553 27.95752,-12.5113753 14.51937,-4.47938141 29.50213,-4.47938141 7.10523,0 14.36491,1.69907571 7.25969,1.6990757 12.97476,5.7150729 5.86954,3.8615361 9.42215,10.5033771 3.70708,6.48738 3.70708,16.21845 0,8.64984 -2.62585,16.063989 -2.47138,7.259687 -7.10522,13.438144 -4.47939,6.023996 -10.50338,11.121223 -6.024,5.097227 -12.97476,9.113224 l -2.78031,-3.861536 q 12.51138,-10.039993 18.38091,-22.087984 6.024,-12.202453 6.024,-25.486136 0,-13.901528 -6.48738,-20.234447 -6.33292,-6.3329184 -18.68983,-6.3329184 -15.75507,0 -32.12798,9.4221474 -16.21845,9.267685 -31.20121,25.640597 Q 101.3554,58.028979 88.844026,80.271425 76.33265,102.35941 68.146195,128.00001 q -5.251689,16.52737 -8.031995,32.59136 -2.780305,15.90952 -2.780305,29.81105 0,57.30519 51.435655,57.30519 29.34767,0 54.0615,-18.53537 24.71383,-18.68983 46.64735,-57.30519 l 8.032,-14.21045 4.17045,2.93477 -8.03199,14.21045 q -11.73907,20.69783 -23.94152,35.83505 -12.20245,15.13722 -25.33168,25.02275 -13.12922,9.88553 -27.49413,14.67384 -14.36491,4.94276 -30.4289,4.94276 z"
                id="path19" />
            <path
                id="path26"
                stroke="#000000"
                d="m 157.1622,26.995568 c -7.68163,-1.620414 -20.09934,15.149486 -22.82325,31.641505 -0.85134,5.019116 -1.13923,15.463171 -1.17983,28.639108 -17.30814,12.373775 -37.032878,24.711709 -40.760984,44.975769 -3.648305,18.52752 3.348477,44.27448 30.317594,49.00091 4.13827,0.73185 7.95161,0.90416 11.41768,0.6302 -0.0212,13.94998 -0.34407,24.90372 -1.25934,29.82283 -3.48223,19.4153 -29.32345,16.64614 -28.49374,5.27101 5.4058,0.80339 10.4646,-2.67703 11.43535,-7.95355 0.99168,-5.39027 -2.66277,-10.60607 -8.20098,-11.62496 -3.04499,-0.5602 -6.0046,0.27746 -8.229614,2.02954 -0.05065,0.0348 -0.108104,0.0685 -0.157235,0.10341 -0.49641,0.36448 -0.943088,0.82636 -1.398608,1.36009 -1.488174,1.71122 -2.732515,4.33485 -3.49713,8.26712 -2.988136,16.24195 36.155807,34.2508 42.048347,2.22199 0.85682,-4.29761 1.01581,-15.57558 0.85187,-29.85372 31.25173,-5.2047 31.89436,-50.06533 4.96923,-55.23063 -2.09457,-0.36351 -4.15465,-0.4909 -6.18371,-0.35543 -0.17583,-7.67399 -0.33832,-15.12037 -0.41976,-21.95059 12.08888,-8.172945 23.42405,-19.545905 27.63742,-43.574546 3.20101,-17.223241 0.003,-32.094919 -6.07328,-33.42006 z m -1.24448,17.002399 c 3.55501,0.305561 5.73179,4.058501 4.41852,11.196711 -1.97245,12.311772 -12.53111,21.43823 -24.73943,30.324407 0.10659,-6.078034 0.34433,-10.974765 0.79613,-13.822116 3.81363,-19.098486 13.59878,-28.208445 19.52478,-27.699002 z M 133.2467,105.5338 c 0.0659,6.65088 0.16809,13.66425 0.27563,20.7286 -19.01429,3.06558 -32.15543,26.36483 -8.90322,42.15134 -13.00596,-15.96585 -2.55635,-30.11018 9.06091,-30.77249 0.22772,14.47512 0.42393,28.75333 0.44119,41.06355 -3.19014,0.50199 -6.80007,0.46929 -10.88996,-0.25375 -10.21311,-1.87897 -29.426458,-12.1334 -24.898464,-36.74526 3.792474,-20.6139 19.622884,-26.362 34.913964,-36.17199 z m 3.0496,32.12859 c 0.45017,0.0548 0.93018,0.15496 1.37671,0.2533 18.57874,3.41802 20.15798,34.21155 -0.49544,40.16043 -0.17684,-12.2661 -0.53499,-26.35941 -0.88125,-40.41373 z"/>
        </svg>
)

export default Logo
